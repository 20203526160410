import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "balance-list bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full font-montserrat" }
const _hoisted_2 = { class: "py-6 text-20px border-b border-gray-lp-400" }
const _hoisted_3 = {
  class: "overflow-auto py-8 space-y-4",
  style: {"height":"100vh"}
}
const _hoisted_4 = { class: "flex flex-wrap items-center justify-between" }
const _hoisted_5 = { class: "flex flex-wrap items-center gap-4" }
const _hoisted_6 = { class: "grid grid-cols-3 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_SectionSttDex = _resolveComponent("SectionSttDex")!
  const _component_SectionDisputeDex = _resolveComponent("SectionDisputeDex")!
  const _component_SectionRtsDex = _resolveComponent("SectionRtsDex")!
  const _component_SectionDexFromSourceDex = _resolveComponent("SectionDexFromSourceDex")!
  const _component_SectionPercentageDexAttempt = _resolveComponent("SectionPercentageDexAttempt")!
  const _component_SectionTotalDexBasedOnReason = _resolveComponent("SectionTotalDexBasedOnReason")!
  const _component_SectionTotalCourierInvalidDex = _resolveComponent("SectionTotalCourierInvalidDex")!
  const _component_SectionCourierDex = _resolveComponent("SectionCourierDex")!
  const _component_SectionTotalDexPerDay = _resolveComponent("SectionTotalDexPerDay")!
  const _component_SectionTotalDexBasedOnTimeRange = _resolveComponent("SectionTotalDexBasedOnTimeRange")!
  const _component_WidgetDownload = _resolveComponent("WidgetDownload")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_Title, { label: _ctx.title }, null, 8, ["label"])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Title, { label: "Ringkasan DEX" }),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_time_range_picker, {
              startDate: _ctx.filter.startDate,
              endDate: _ctx.filter.endDate,
              onSelect: _ctx.setDateRange,
              default: _ctx.$t('hari ini'),
              isCloseDateAfterSelect: "",
              leftPosition: false,
              maxDate: new Date()
            }, null, 8, ["startDate", "endDate", "onSelect", "default", "maxDate"]),
            _createVNode(_component_LpButton, {
              textColor: "white",
              title: "Download Data",
              onClick: _ctx.onDownload
            }, null, 8, ["onClick"])
          ])
        ]),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionSttDex, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionDisputeDex, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_6, [
          (_ctx.reRenderComponent)
            ? (_openBlock(), _createBlock(_component_SectionRtsDex, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.reRenderComponent)
            ? (_openBlock(), _createBlock(_component_SectionDexFromSourceDex, { key: 1 }))
            : _createCommentVNode("", true),
          (_ctx.reRenderComponent)
            ? (_openBlock(), _createBlock(_component_SectionPercentageDexAttempt, { key: 2 }))
            : _createCommentVNode("", true)
        ]),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionTotalDexBasedOnReason, { key: 2 }))
          : _createCommentVNode("", true),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionTotalCourierInvalidDex, { key: 3 }))
          : _createCommentVNode("", true),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionCourierDex, { key: 4 }))
          : _createCommentVNode("", true),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionTotalDexPerDay, { key: 5 }))
          : _createCommentVNode("", true),
        (_ctx.reRenderComponent)
          ? (_openBlock(), _createBlock(_component_SectionTotalDexBasedOnTimeRange, { key: 6 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_WidgetDownload)
  ], 64))
}