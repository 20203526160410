import formatInputFloat from "./FormatInputFloat";
import formatPriceWithoutCurrency from "./FormatPriceWithoutCurrency";

const convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutesValue = Number(formatInputFloat(minutes % 60));
  const remainingMinutesDisplay = formatPriceWithoutCurrency(
    remainingMinutesValue
  );

  if (hours > 0) {
    if (remainingMinutesValue) {
      return `${hours} Jam ${remainingMinutesDisplay} Menit`;
    } else {
      return `${hours} Jam`;
    }
  } else {
    return `${remainingMinutesDisplay} Menit`;
  }
};

export default convertMinutesToHoursAndMinutes;
