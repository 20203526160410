import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between" }
const _hoisted_2 = { class: "flex flex-row w-3/4 gap-4" }
const _hoisted_3 = { class: "w-6/20" }
const _hoisted_4 = { class: "w-2/5" }
const _hoisted_5 = { class: "w-6/20" }
const _hoisted_6 = { class: "border-l border-r p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_Search = _resolveComponent("Search")!
  const _component_SelectReason = _resolveComponent("SelectReason")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "flex flex-col gap-4 p-4" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_TooltipV2, {
          text: _ctx.title,
          fontSize: 14,
          fontWeight: "semibold"
        }, null, 8, ["text"]),
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_Search, {
              onInput: _ctx.onSearch,
              onClear: _ctx.onSearch,
              value: _ctx.search,
              placeholder: "Cari nama kurir",
              class: "w-full"
            }, null, 8, ["onInput", "onClear", "value"])
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_SelectReason, {
              modelValue: _ctx.filter.reasonCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter.reasonCode = $event)),
              onChange: _ctx.onSelectReason,
              class: "w-full"
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_CustomDropdown, {
              selectedItem: _ctx.filter.orderBy,
              options: _ctx.orderBy,
              placeholder: "Urutkan",
              onSelect: _ctx.selectOrderBy,
              class: "w-full"
            }, null, 8, ["selectedItem", "options", "onSelect"])
          ])
        ])
      ]),
      _createVNode(_component_TableV2, {
        borderTop: false,
        borderBottom: false,
        pinnedSubstractHeight: "200px",
        infiniteScroll: !_ctx.filter.clickFilter,
        hidePagination: "",
        isNoPaddingBottom: "",
        isSpaceTop: false,
        totalPercentFreeze: 15,
        loading: _ctx.courierdata.loading,
        columns: _ctx.columns(),
        data: _ctx.courierdata.data,
        isEmpty: _ctx.isEmpty,
        pagination: _ctx.courierdata.pagination,
        mptyHeaderMessage: "Tidak Ada Kurir DEX",
        mptyMessage: "Mulai buat Kurir DEX dan cek daftarnya disini",
        onRequest: _cache[2] || (_cache[2] = () => _ctx.fetchData('courier')),
        onTryAgain: () => _ctx.fetchData('courier')
      }, {
        "expand-row": _withCtx(({item}) => [
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_TableV2, {
              isStickyHeader: false,
              borderTop: false,
              borderBottom: false,
              infiniteScroll: "",
              hidePagination: "",
              isNoPaddingBottom: "",
              isSpaceTop: false,
              bgHeader: "bg-aqua-lp-200",
              totalPercentFreeze: 15,
              zIndexHeader: "z-0",
              loading: _ctx.reasonData.loading,
              columns: _ctx.columns(true),
              data: item.reasonData,
              isEmpty: item.reasonData.length,
              pagination: _ctx.reasonData.pagination,
              mptyHeaderMessage: "Tidak Ada Alasan DEX",
              mptyMessage: "Mulai buat Alasan DEX dan cek daftarnya disini",
              onRequest: () => _ctx.fetchData('reason', item),
              onTryAgain: () => _ctx.fetchData('reason', item)
            }, null, 8, ["loading", "columns", "data", "isEmpty", "pagination", "onRequest", "onTryAgain"])
          ])
        ]),
        _: 1
      }, 8, ["infiniteScroll", "loading", "columns", "data", "isEmpty", "pagination", "onTryAgain"])
    ]),
    _: 1
  }))
}