import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 flex flex-col gap-4" }
const _hoisted_2 = { class: "flex flex-row gap-4 justify-between items-center" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_AreaChart = _resolveComponent("AreaChart")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mr-1" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_TooltipV2, {
            text: "Total DEX per hari",
            fontSize: 14,
            fontWeight: "semibold"
          })
        ])
      ]),
      _createVNode("div", _hoisted_3, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "1rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "20rem"
              })
            ]))
          : (!_ctx.loading && _ctx.chart.labels.length && _ctx.chart.datasets.length)
            ? (_openBlock(), _createBlock(_component_AreaChart, {
                key: 1,
                seriesData: _ctx.chart.datasets,
                categoriesData: _ctx.chart.labels,
                isPercentage: false
              }, null, 8, ["seriesData", "categoriesData"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}