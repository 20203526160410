
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { SummaryTopDex } from "@/domain/entities/DexAssessment";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Vue, prop, Options } from "vue-class-component";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";

class Props {
  title = prop<string>({
    default: "",
    type: String
  });
  showOrderFilter = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  components: {}
})
export default class SectionTableExpand extends Vue.with(Props) {
  mounted(): void {
    this.fetchData("courier");
  }
  courierdata = new ResponsePayloadV2();
  reasonData = new ResponsePayloadV2();
  courierDetail = new SummaryTopDex();

  get filter() {
    return DexAssessmentController.filter;
  }
  async fetchData(type: "courier" | "reason", item = new SummaryTopDex()) {
    try {
      const { startDate, endDate } = DexAssessmentController.filter;

      const orderBy =
        type === "courier" ||
        (type === "reason" && this.filter.orderBy.value.match(/invalid/gi))
          ? this.filter.orderBy.value?.replace(/_desc|_asc/gi, "")
          : "";

      let payload = new ApiRequestList({
        page: this.courierdata.pagination.page,
        limit: type === "courier" ? 10 : 20,
        startDate: formatDateNumber(startDate),
        endDate: formatDateNumber(endDate),
        courierName: this.search,
        reasonDex: this.filter.reasonCode.toString(),
        orderBy,
        sortBy: this.filter.sortBy
      });
      if (type === "courier") {
        this.courierdata.loading = true;
        payload = new ApiRequestList({
          ...payload,
          endpoint: "dex-courier"
        });
      } else {
        this.reasonData.loading = true;
        this.courierdata.data[this.courierDetail.index].reasonData = [];
        payload = new ApiRequestList({
          ...payload,
          endpoint: `dex-courier-reason/${item.courierPhone}`
        });
      }
      const res = await DexAssessmentController.getDashboardConsole(payload);

      if (type === "courier") {
        this.onCourierData(res);
      } else {
        this.onReasonData(res);
      }
    } catch (err) {
      this.courierdata.message = parsingErrorResponse(err).type;
    } finally {
      if (this.filter?.clickFilter) {
        this.filter.clickFilter = false;
      }
      this.courierdata.loading = false;
      this.reasonData.loading = false;
    }
  }
  onCourierData(res: ResponsePayloadV2) {
    const { clickFilter } = DexAssessmentController.filter;

    let tempData: SummaryTopDex[] = [];
    if (clickFilter) {
      tempData = res.data;
    } else {
      tempData = [...this.courierdata.data, ...res.data];
    }
    this.courierdata = new ResponsePayloadV2({
      data: tempData
    });
  }
  onReasonData(res: ResponsePayloadV2) {
    const { clickFilter } = DexAssessmentController.filter;

    let tempData: SummaryTopDex[] = [];
    if (clickFilter) {
      tempData = res.data;
    } else {
      tempData = [
        ...this.courierdata.data[this.courierDetail.index].reasonData,
        ...res.data
      ];
    }
    this.courierdata.data[this.courierDetail.index].reasonData = tempData;
  }

  get defaultColumns(): any[] {
    return [
      {
        name: "",
        key: "button_column",
        styleHead: "w-16 text-left",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b border-l rounded-tl-lg" : "",
        styleButton: (item: SummaryTopDex) => ({
          textColor: "red-lp-100",
          flat: true,
          small: true,
          iconV2: true,
          icon: item.expand ? "chevron-up" : "chevron-down",
          clickFunction: this.onExpandCourier
        })
      },
      {
        name: "No",
        styleHead: "w-16 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (_: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.courierdata.pagination.limit *
              (this.courierdata.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "Nama Kurir",
        styleHead: "w-52 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${item.name}</span>`
      },
      {
        name: "Total DEL",
        styleHead: "w-32 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.totalDel
          )}</span>`
      },
      {
        name: "Total DEX",
        styleHead: "w-32 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.totalDex
          )}</span>`
      }
    ];
  }

  get nextColumns(): any[] {
    return [
      {
        name: "Persentase DEX",
        styleHead: "w-40 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageDex
          )}%</span>`
      },
      {
        name: "Total DEX tidak sesuai",
        styleHead: "w-56 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.totalInvalid
          )}</span>`
      },
      {
        name: "Persentase DEX tidak sesuai",
        styleHead: "w-60 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageInvalid
          )}%</span>`
      },
      {
        name: "Total DEX sesuai",
        styleHead: "w-56 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.totalValid
          )}</span>`
      },
      {
        name: "Persentase DEX sesuai",
        styleHead: "w-60 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b border-r rounded-tr-lg" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageValid
          )}%</span>`
      }
    ];
  }

  columns(reason = false) {
    if (reason) {
      return this.columnReason;
    }
    return this.defaultColumns.concat(this.nextColumns);
  }
  get columnReason() {
    const col = this.defaultColumns.concat(this.nextColumns);

    col.unshift({
      name: "Alasan DEX",
      styleHead: "w-80 text-left whitespace-no-wrap",
      advancedStyle: (item: SummaryTopDex) =>
        item.expand ? "border-t border-b" : "",
      render: (item: SummaryTopDex) =>
        `<span class='text-left text-black-lp-300 flex justify-start'>${item.reason}</span>`
    });

    const newCol: any[] = [];

    for (const item of col) {
      if (
        !!item.name &&
        !item.name.match(/^No$|^Nama Kurir$|^Total DEL$|^Persentase DEX$/gi)
      ) {
        if (item.name === "Total DEX") {
          newCol.push({
            ...item,
            styleHead: "w-full text-left whitespace-no-wrap"
          });
        } else if (item.name === "Persentase DEX sesuai") {
          newCol.push({
            ...item,
            styleHead: "w-56 text-left whitespace-no-wrap"
          });
        } else {
          newCol.push(item);
        }
      }
    }
    return newCol;
  }

  formatDecimal(value: number | string) {
    return formatPriceWithoutCurrency(Number(value));
  }

  get isEmptyDate(): boolean {
    return !this.filter.startDate && !this.filter.endDate;
  }
  get isEmpty(): boolean {
    return (
      this.isEmptyDate &&
      !this.courierdata.data.length &&
      !this.filter.reasonCode &&
      !this.filter.reasonCode?.length &&
      !this.filter.city &&
      !this.filter.city?.code
    );
  }

  onExpandCourier(item: SummaryTopDex, index: number) {
    if (!item.expand) {
      this.courierdata.data[index].expand = true;
      if (!item.courierData.length) {
        this.courierDetail = new SummaryTopDex({ ...item, index });
        this.fetchData("reason", item);
      }
    } else {
      this.courierdata.data[index].expand = false;
    }
  }
  // search
  search = "";
  onSearch(value: string) {
    if (!value || value.length >= 3) {
      this.search = value;
      this.filter.clickFilter = true;
      this.fetchData("courier");
    }
  }

  onSelectReason() {
    this.filter.clickFilter = true;
    this.fetchData("courier");
  }

  get orderBy(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "Percentage DEX Tertinggi",
        value: "percentage_dex_desc"
      }),
      new OptionsClass({
        name: "Percentage DEX Invalid Tertinggi",
        value: "percentage_invalid_desc"
      }),
      new OptionsClass({
        name: "Percentage DEX Terendah",
        value: "percentage_dex_asc"
      }),
      new OptionsClass({
        name: "Percentage DEX Invalid Terendah",
        value: "percentage_invalid_asc"
      })
    ];
  }
  selectOrderBy(data: OptionsClass) {
    Object.assign(this.filter, {
      orderBy: data,
      sortBy: data.value?.split("_")[2],
      clickFilter: true
    });
    this.fetchData("courier");
  }
}
