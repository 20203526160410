
import { Vue, prop } from "vue-class-component";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { sourceDex, attemptDex } from "./data";
import { SummaryTicket } from "@/domain/entities/DexAssessment";

class Props {
  typeChart = prop<string>({
    default: "",
    type: String,
  });
  title = prop<string>({
    default: "",
    type: String,
  });
  tooltip = prop<string>({
    default: "",
    type: String,
  });
}

export default class DonutChart extends Vue.with(Props) {
  mounted() {
    this.fetchData();
  }

  get dataCharts() {
    return this.typeChart === "source-dex" ? sourceDex : attemptDex;
  }

  loading = false;
  isError = false;
  errorMessage = "";
  async fetchData() {
    this.loading = true;
    this.isError = false;
    try {
      const { data } = await DexAssessmentController.getDashboardConsole(
        new ApiRequestList({
          endpoint: this.typeChart,
          startDate: DexAssessmentController.filter.startDate
            ? new Date(
                DexAssessmentController.filter.startDate
              ).toLocaleDateString("fr-CA")
            : "",
          endDate: DexAssessmentController.filter.endDate
            ? new Date(
                DexAssessmentController.filter.endDate
              ).toLocaleDateString("fr-CA")
            : "",
        })
      );

      this.typeChart === "source-dex"
        ? this.setValueSourceDex(data)
        : this.setValueAttemptDex(data);
    } catch (error) {
      this.isError = true;
      this.errorMessage = parsingErrorResponse(error).type;
    } finally {
      this.loading = false;
    }
  }

  setValueSourceDex(data: SummaryTicket) {
    sourceDex.series = [data.sourceDexDriverApp, data.sourceDexGenesis];
    sourceDex.detail[0].value = data.sourceDexDriverApp;
    sourceDex.detail[1].value = data.sourceDexGenesis;

    sourceDex.detail[0].percentage = data.percentageSourceDexDriverApp;
    sourceDex.detail[1].percentage = data.percentageSourceDexGenesis;
  }

  setValueAttemptDex(data: SummaryTicket) {
    attemptDex.series = [
      data.totalDexOneAttempt,
      data.totalDexSecondAttempt,
      data.totalDexMoreThanEqThirdAttempt,
    ];
    attemptDex.detail[0].value = data.totalDexOneAttempt;
    attemptDex.detail[1].value = data.totalDexSecondAttempt;
    attemptDex.detail[2].value = data.totalDexMoreThanEqThirdAttempt;

    attemptDex.detail[0].percentage = data.percentageDexOneAttempt;
    attemptDex.detail[1].percentage = data.percentageDexSecondAttempt;
    attemptDex.detail[2].percentage = data.percentageDexMoreThanEqThirdAttempt;
  }

  formatDecimal(value: number) {
    return formatPriceWithoutCurrency(value);
  }
}
