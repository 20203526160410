
import { Options, Vue } from "vue-class-component";
import TableExpand from "./table-expand-console.vue";

@Options({
  components: {
    TableExpand
  }
})
export default class SectionCourierDex extends Vue {}
