
import {
  formatDateNumber,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import formatDateBasedTimezoneValue from "@/app/infrastructures/misc/common-library/FormatDateBasedTimezoneValue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { SummaryTotalDexPerDay } from "@/domain/entities/DexAssessment";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class SectionTotalDexPerDay extends Vue {
  mounted(): void {
    this.fetchData();
  }

  get filter() {
    return DexAssessmentController.filter;
  }

  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.loading = true;
      const res = await DexAssessmentController.getTotalTicketPerDay(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          ticketType: "l1_l2",
          partnerId: AccountController.accountData.account_type_detail?.id
        })
      );
      this.chart = this.filterMax7Data(res.data);
    } catch (err) {
      this.errorMessage = parsingErrorResponse(err).type;
    } finally {
      this.loading = false;
    }
  }
  chart = new SummaryTotalDexPerDay();
  loading = false;
  errorMessage = false;

  reduceTo7Data(data: any[]) {
    const temp = [],
      res = [],
      div = Math.floor(data.length / 7) || 1;

    for (const [index, item] of data.entries()) {
      const last = index === data.length - 1;

      if (!(index % div) || last) {
        temp.push(item);
        if (last && res.length === 6) {
          res.push(temp[temp.length - 1]);
        } else if (res.length < 6) {
          res.push(item);
        }
      }
    }
    return res;
  }
  filterMax7Data(data: SummaryTotalDexPerDay) {
    return new SummaryTotalDexPerDay({
      labels: this.reduceTo7Data(data.labels).map((item: string) =>
        formatDateBasedTimezoneValue(item, "DD MMM")
      ),
      datasets: this.reduceTo7Data(data.datasets)
    });
  }
}
