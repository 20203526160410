
import { Options, Vue } from "vue-class-component";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import SectionSttDex from "./components/section-stt-dex.vue";
import SectionDisputeDex from "./components/section-dispute-dex.vue";
import SectionRtsDex from "./components/section-percentage-rts-dex.vue";
import SectionDexFromSourceDex from "./components/section-dex-from-source-dex.vue";
import SectionPercentageDexAttempt from "./components/section-percentage-dex-attempt.vue";
import SectionTotalDexBasedOnReason from "./components/section-total-dex-based-on-reason.vue";
import SectionTotalCourierInvalidDex from "./components/section-total-courier-invalid-dex.vue";
import SectionCourierDex from "./components/section-courier-dex.vue";
import SectionTotalDexPerDay from "./components/section-total-dex-per-day.vue";
import SectionTotalDexBasedOnTimeRange from "./components/section-total-dex-based-on-time-range.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import {
  formatDateNumber,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { ReportController } from "@/app/ui/controllers/ReportController";

@Options({
  components: {
    SectionSttDex,
    SectionDisputeDex,
    SectionRtsDex,
    SectionDexFromSourceDex,
    SectionPercentageDexAttempt,
    SectionTotalDexBasedOnReason,
    SectionTotalCourierInvalidDex,
    SectionCourierDex,
    SectionTotalDexPerDay,
    SectionTotalDexBasedOnTimeRange
  }
})
export default class DashboardConsole extends Vue {
  created(): void {
    DexAssessmentController.onResetFilter();
    DexAssessmentController.setStartDate(new Date().toDateString());
    DexAssessmentController.setEndDate(new Date().toDateString());
  }

  get title(): string {
    return String(this.$route.meta.title || "");
  }

  reRenderComponent = false;
  get filter() {
    return DexAssessmentController.filter;
  }
  async setDateRange(value: any[]) {
    DexAssessmentController.onResetFilter();
    DexAssessmentController.setStartDate(value[0]);
    DexAssessmentController.setEndDate(value[1]);

    const valid = value[0] && value[1];
    if (valid) {
      this.reRenderComponent = false;
      this.$nextTick(() => {
        this.reRenderComponent = true;
      });
    }
  }

  // download
  async onDownload() {
    const { startDate, endDate } = DexAssessmentController.filter;

    try {
      MainAppController.showLoading();
      await DexAssessmentController.getDashboardConsole(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          endpoint: "download"
        })
      );

      ReportController.onGetListDownloadReport();
      ReportController.setOpenDownloadRequest(true);
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Download Gagal!", () => {
          this.onDownload();
          MainAppController.closeErrorMessage();
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  beforeRouteLeave(_: any, __: any, next: any) {
    next();
    DexAssessmentController.onResetFilter();
  }
}
