import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_BarChart, {
      type: "courier",
      title: "Total kurir DEX invalid",
      placeholderStatus: "Pilih status"
    })
  ]))
}