import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTotalTicket = _resolveComponent("SectionTotalTicket")!

  return (_openBlock(), _createBlock(_component_SectionTotalTicket, {
    title: "DEX Sanggah",
    type: "console-dispute",
    showSubData: false,
    leftClass: "w-full mr-1"
  }))
}