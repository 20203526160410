
import { Vue, Options } from "vue-class-component";
import DonutChart from "./charts/donut-chart.vue";

@Options({
  components: {
    DonutChart,
  },
})
export default class SectionDexFromSourceDex extends Vue {}
