import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 flex flex-col gap-4" }
const _hoisted_2 = { class: "grid justify-items-center" }
const _hoisted_3 = { class: "grid justify-items-center" }
const _hoisted_4 = { class: "flex gap-3" }
const _hoisted_5 = { class: "flex flex-wrap items-center gap-1" }
const _hoisted_6 = {
  key: 1,
  class: "text-12px text-gray-lp-800 font-medium capitalize"
}
const _hoisted_7 = { class: "flex flex-row items-center gap-1" }
const _hoisted_8 = {
  key: 1,
  class: "text-14px text-black-lp-300 font-semibold"
}
const _hoisted_9 = {
  key: 3,
  class: "text-14px text-gray-lp-800 font-medium capitalize"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_skeleton = _resolveComponent("skeleton")!
  const _component_DonutChart = _resolveComponent("DonutChart", true)!
  const _component_bullet_point = _resolveComponent("bullet-point")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mr-1" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_TooltipV2, {
            text: _ctx.title,
            description: _ctx.tooltip,
            fontSize: 14,
            fontWeight: "semibold"
          }, null, 8, ["text", "description"])
        ]),
        _createVNode("div", _hoisted_3, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_skeleton, {
                key: 0,
                height: "5rem",
                width: "8rem"
              }))
            : (_openBlock(), _createBlock(_component_DonutChart, {
                key: 1,
                chartSeries: _ctx.dataCharts.series,
                chartColor: _ctx.dataCharts.color,
                isShowTotal: "",
                chartTitle: "STT",
                chartHeight: "150",
                chartWidth: "150",
                size: "50%"
              }, null, 8, ["chartSeries", "chartColor"])),
          _createVNode("div", _hoisted_4, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataCharts.detail, (item) => {
              return (_openBlock(), _createBlock("div", {
                key: item,
                class: "flex flex-col gap-1"
              }, [
                _createVNode("div", _hoisted_5, [
                  _createVNode(_component_bullet_point, {
                    style: `color: ${item.color}`,
                    class: "w-2"
                  }, null, 8, ["style"]),
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_skeleton, {
                        key: 0,
                        height: "2rem",
                        width: "7rem"
                      }))
                    : (_openBlock(), _createBlock("div", _hoisted_6, _toDisplayString(item.origin), 1))
                ]),
                _createVNode("div", _hoisted_7, [
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_skeleton, {
                        key: 0,
                        height: "2rem",
                        width: "1.5rem"
                      }))
                    : (_openBlock(), _createBlock("div", _hoisted_8, _toDisplayString(_ctx.formatDecimal(item.percentage)) + "% ", 1)),
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_skeleton, {
                        key: 2,
                        height: "2rem",
                        width: "2.5rem"
                      }))
                    : (_openBlock(), _createBlock("div", _hoisted_9, " (" + _toDisplayString(_ctx.formatDecimal(item.value)) + " Tiket) ", 1))
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}