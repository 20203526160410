import { OptionsClass } from "@/domain/entities/MainApp";

export const sourceDex = {
  series: [0, 0],
  color: ["#4185F7", "#9BC4FA"],
  detail: [
    {
      name: "driverApp",
      value: 0,
      color: "#4185F7",
      origin: "Driver Apps",
      percentage: 0
    },
    {
      name: "genesis",
      value: 0,
      color: "#9BC4FA",
      origin: "Genesis",
      percentage: 0
    }
  ]
};

export const attemptDex = {
  series: [0, 0, 0],
  color: ["#D4797C", "#E13E49", "#B82025"],
  detail: [
    {
      name: "one",
      value: 0,
      color: "#D4797C",
      origin: "1 Kali",
      percentage: 0
    },
    {
      name: "two",
      value: 0,
      color: "#E13E49",
      origin: "2 Kali",
      percentage: 0
    },
    {
      name: "more",
      value: 0,
      color: "#B82025",
      origin: ">= 3 Kali",
      percentage: 0
    }
  ]
};

export const rtsDex = [
  {
    key: "totalRts",
    title: "Persentase DEX yang perlu di RTS",
    total: new OptionsClass({
      key: "totalRts",
      value: "0"
    }),
    percentage: {
      key: "percentageRts",
      value: "0"
    }
  }
];

export const sortOptions: any = {
  dex: [
    {
      name: "Urutkan",
      sortBy: "",
      orderBy: "",
      value: ""
    },
    {
      name: "Total DEX Tertinggi",
      sortBy: "desc",
      orderBy: "total_dex",
      value: "total_dex_desc"
    },
    {
      name: "Total DEX Invalid Tertinggi",
      sortBy: "desc",
      orderBy: "total_invalid",
      value: "total_invalid_desc"
    },
    {
      name: "Total DEX Invalid Sanggah Ditolak Tertinggi",
      sortBy: "desc",
      orderBy: "total_rejected",
      value: "total_rejected_desc"
    },
    {
      name: "Total DEX Terendah",
      sortBy: "asc",
      orderBy: "total_dex",
      value: "total_dex_asc"
    },
    {
      name: "Total DEX Invalid Terendah",
      sortBy: "asc",
      orderBy: "total_invalid",
      value: "total_invalid_asc"
    },
    {
      name: "Total DEX Invalid Sanggah Ditolak Terendah",
      sortBy: "asc",
      orderBy: "total_rejected",
      value: "total_rejected_asc"
    }
  ],
  courier: [
    {
      name: "Urutkan",
      sortBy: "",
      orderBy: "",
      value: ""
    },
    {
      name: "Total DEX Invalid Tertinggi",
      sortBy: "desc",
      orderBy: "total_invalid",
      value: "total_invalid_desc"
    },
    {
      name: "Total Sanggah Ditolak Tertinggi",
      sortBy: "desc",
      orderBy: "total_rejected",
      value: "total_rejected_desc"
    },
    {
      name: "Total DEX Invalid Terendah",
      sortBy: "asc",
      orderBy: "total_invalid",
      value: "total_invalid_asc"
    },
    {
      name: "Total Sanggah Ditolak Terendah",
      sortBy: "asc",
      orderBy: "total_rejected",
      value: "total_rejected_asc"
    },
  ]
}

export const statusOptions: any = {
  dex: [
    {
      name: "All Status",
      value: "",
      color: ""
    },
    {
      name: "DEX Valid",
      value: "total_valid",
      color: "#7ACB90"
    },
    {
      name: "DEX Invalid",
      value: "total_invalid",
      color: "#D4797C"
    },
    {
      name: "DEX Invalid Disanggah",
      value: "total_requested",
      color: "#FFCB01"
    },
    {
      name: "DEX Invalid Sanggah Disetujui",
      value: "total_approved",
      color: "#8E78DB"
    },
    {
      name: "DEX Invalid Sanggah Ditolak",
      value: "total_rejected",
      color: "#EF7C5C"
    }
  ],
  courier: [
    {
      name: "All Status",
      value: "",
      color: ""
    },
    {
      name: "DEX Invalid",
      value: "total_invalid",
      color: "#D4797C"
    },
    {
      name: "DEX Invalid Diterima",
      value: "total_accepted",
      color: "#58C5E2"
    },
    {
      name: "DEX Invalid Disanggah",
      value: "total_requested",
      color: "#FFCB01"
    },
    {
      name: "DEX Invalid Sanggah Disetujui",
      value: "total_approved",
      color: "#BC9AFD"
    },
    {
      name: "DEX Invalid Sanggah Ditolak",
      value: "total_rejected",
      color: "#EF7C5C"
    },
  ]
}