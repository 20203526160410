
import { Options, Vue } from "vue-class-component";
import PercentageDonutChart from "@/app/ui/views/dex-assessment/dashboard/components/section-percentage-donut-chart.vue";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import {
  formatDateNumber,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { rtsDex } from "./charts/data";

@Options({
  components: {
    PercentageDonutChart
  }
})
export default class SectionRtsDex extends Vue {
  mounted(): void {
    this.fetchData();
    DexAssessmentController.setDonutChartData(rtsDex);
  }

  get rtsDex(): any[] {
    return DexAssessmentController.donutChartData;
  }
  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = DexAssessmentController.filter;

    try {
      this.dashboardData.loading = true;
      const { data } = await DexAssessmentController.getDashboardConsole(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          endpoint: "rts"
        })
      );
      DexAssessmentController.onMappingDonutChartData(data);
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }
}
