
import { Options, Vue } from "vue-class-component";
import SectionTotalTicketBasedRangeTime from "@/app/ui/views/dex-assessment/dashboard/components/section-total-ticket-based-range-time.vue";

@Options({
  components: {
    SectionTotalTicketBasedRangeTime,
  },
})
export default class SectionTotalDexBasedOnTimeRange extends Vue {
  mounted() {
    this.fetchSummaryTicketBasedRangeTime();
  }
  
  async fetchSummaryTicketBasedRangeTime() {
    const refs: any = this.$refs["ticket-based-range-time"];
    if (refs) {
      refs.fetchSummaryTicketBasedRangeTime();
    }
  }
}
