import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "text-12px xxl:text-16px text-gray-lp-300 font-semibold" }
const _hoisted_3 = { class: "flex flex-row gap-x-2 w-400px" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col p-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_skeleton = _resolveComponent("skeleton")!
  const _component_BarCharts = _resolveComponent("BarCharts")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "px-3 pt-4 mr-1" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", null, [
          _createVNode(_component_TooltipV2, {
            text: _ctx.title,
            fontSize: 14,
            fontWeight: "semibold"
          }, null, 8, ["text"]),
          _createVNode("h3", _hoisted_2, _toDisplayString(_ctx.total), 1)
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_CustomDropdown, {
            selectedItem: _ctx.statusSelected,
            options: _ctx.statusOptions,
            placeholder: _ctx.placeholderStatus,
            onSelect: _ctx.statusBySelect
          }, null, 8, ["selectedItem", "options", "placeholder", "onSelect"]),
          _createVNode(_component_CustomDropdown, {
            selectedItem: _ctx.sortSelected,
            options: _ctx.sortOptions,
            placeholder: "Urutkan",
            onSelect: _ctx.sortedbySelect
          }, null, 8, ["selectedItem", "options", "onSelect"])
        ])
      ]),
      (_ctx.dashboardData.loading)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            _createVNode(_component_skeleton, {
              width: "100%",
              height: "20rem"
            })
          ]))
        : (_openBlock(), _createBlock(_component_BarCharts, _mergeProps({ key: 1 }, _ctx.byReasonChart), null, 16))
    ]),
    _: 1
  }))
}