
import { Vue, prop } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { SummaryTicket } from "@/domain/entities/DexAssessment";
import { sortOptions, statusOptions } from "./data";

class Props {
  type = prop<string>({
    default: "",
    type: String,
  });
  title = prop<string>({
    default: "",
    type: String,
  });
  placeholderStatus = prop<string>({
    default: "",
    type: String,
  });
}

export default class BarChart extends Vue.with(Props) {
  mounted() {
    this.fetchData();
  }

  byReasonChart = {
    series: [] as any[],
    categories: [] as any[],
    height: 0,
    color: ["#7ACB90", "#D4797C", "#FFCB01", "#8E78DB", "#EF7C5C"],
    rounded: false,
    barHeight: "50%",
    total: 0,
  };

  get filter() {
    return DexAssessmentController.filter;
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = DexAssessmentController.filter;

    try {
      this.dashboardData.loading = true;
      const res = await DexAssessmentController.getDashboardConsole(
        new ApiRequestList({
          endpoint: this.serviceCondition.endpoint,
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          ...this.serviceCondition.params,
        })
      );

      const data: SummaryTicket = res.data;
      const height = data.dexReasonLabels.length * 45;
      this.byReasonChart = {
        ...this.byReasonChart,
        series: data.dexReasonDataset.map((item: any) => ({
          name: item.label,
          data: item.data,
        })),
        color: this.getColorLegend(data),
        categories: data.dexReasonLabels,
        height: height <= 175 ? 175 : height,
        total: this.type === "dex" ? data.dexReasonTotalDex : data.dexReasonLabels?.length,
      };
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }

  getColorLegend(data: SummaryTicket) {
    if (data.dexReasonDataset?.length > 1) {
      return this.serviceCondition.legendColor
    }

    const dataColor = statusOptions[this.type].find((item: any) => item.name === data.dexReasonDataset[0].label);
    return [dataColor.color];
  }

  get serviceCondition() {
    const service: any = {
      dex: {
        endpoint: "dex-by-reason",
        legendColor: ["#7ACB90", "#D4797C", "#FFCB01", "#8E78DB", "#EF7C5C"],
        params: {
          status: this.filter.statusReasonDex.value,
          orderBy: this.filter.sortReasonDex.label,
          sortBy: this.filter.sortReasonDex.key,
        },
        suffix: "DEX"
      },
      courier: {
        endpoint: "dex-courier-invalid",
        legendColor: ["#D4797C", "#58C5E2", "#FFCB01", "#BC9AFD", "#EF7C5C"],
        params: {
          status: this.filter.statusCourier.value,
          orderBy: this.filter.sortCourier.label,
          sortBy: this.filter.sortCourier.key,
        },
        suffix: "kurir"
      },
    };

    return service[this.type];
  }

  get total(): string {
    return `${this.byReasonChart.total} ${this.serviceCondition.suffix}`;
  }

  get sortOptions(): OptionsClass[] {
    return sortOptions[this.type].map((item: any) => {
      return new OptionsClass({
        name: item.name,
        value: item.value,
        label: item.orderBy,
        key: item.sortBy,
      });
    });
  }

  sortedbySelect(data: OptionsClass) {
    const value =
      this.type === "dex"
        ? { sortReasonDex: data }
        : { sortCourier: data };
    Object.assign(this.filter, value);
    this.fetchData();
  }

  get statusOptions(): OptionsClass[] {
    return statusOptions[this.type].map((item: any) => {
      return new OptionsClass({
        name: item.name,
        value: item.value,
      });
    });
  }

  statusBySelect(data: OptionsClass) {
    const value =
      this.type === "dex"
        ? { statusReasonDex: data }
        : { statusCourier: data };
    Object.assign(this.filter, value);
    this.fetchData();
  }

  get statusSelected() {
    return this.type === "dex" ? this.filter.statusReasonDex : this.filter.statusCourier;
  }

  get sortSelected() {
    return this.type === "dex" ? this.filter.sortReasonDex : this.filter.sortCourier;
  }
}
